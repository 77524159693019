"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateRegex = exports.hexColorRegEx = exports.redirectUriRegEx = exports.passwordRegEx = exports.usernameRegEx = exports.phoneRegEx = exports.emailRegEx = void 0;
exports.emailRegEx = /^\S+@\S+\.\S+$/;
exports.phoneRegEx = /^\d+$/;
exports.usernameRegEx = /^[A-Z_a-z]\w*$/;
exports.passwordRegEx = /^.{6,}$/;
exports.redirectUriRegEx = /^https?:\/\//;
exports.hexColorRegEx = /^#[\da-f]{3}([\da-f]{3})?$/i;
exports.dateRegex = /^\d{4}(-\d{2}){2}/;
